<template>
	<div id="doctor" style="min-height: 100vh;">
		<v-head />
		<div class="content" v-if="doctor">
			<div class="doctor-info">
				<div class="head">
					<span :class="{'icon-doctor': true, 'man': doctor.sex == 1}"></span>
					<div class="col">
						<h1 class="doctor-name" v-text="doctor.username"></h1>
						<div class="address-list">
							<router-link class="address" v-for="(address, i) in addressList" :key="i" :to="'/doctor/list?AreaLv1='+address.v1+'&AreaLv2='+address.v2" v-text="address.name"></router-link>
						</div>
					</div>
				</div>
				<div class="hint s">畢業院校</div>
				<div class="value s" v-text="doctor.graduateschool"></div>
				<div class="hint y">畢業年份</div>
				<div class="value y" v-text="doctor.graduatedate"></div>
				<div class="hint i">個人簡介</div>
				<div class="value i" v-text="doctor.workexperience"></div>
			</div>
			<div class="col">
				<ul class="tabs">
					<li :class="{'tab': true, 'current': tabIndex == 1}"><router-link :to="'/doctor/'+doctor.globaluserid">供職醫院</router-link></li>
					<li :class="{'tab': true, 'current': tabIndex == 2}"><router-link :to="'/doctor/'+doctor.globaluserid + '/ask'">咨詢問答</router-link></li>
					<li :class="{'tab': true, 'current': tabIndex == 3}"><router-link :to="'/doctor/'+doctor.globaluserid + '/news'">健康資訊</router-link></li>
				</ul>
				<router-view class="router-view" :doctor="doctor"></router-view>
			</div>
		</div>
		<loading v-if="loading"/>
	</div>
</template>

<script>
	import vHead from '@/layouts/MobileHead.vue'
	import Loading from '@/layouts/Loading.vue'
	import doctor_detail from '@/views/mixins/doctor_detail'
	export default {
		mixins: [doctor_detail],
		components: {
			vHead,
			Loading,
		},
		data(){
			return {
				addressList: [],
				tabIndex: 1,
				doctor: null,
				loading: true,
			}
		},
		mounted: function(){
			var path = this.$route.path
			this.breadcrumbText = '醫師簡介'
			this.tabIndex = 1
			if(/^\/doctor\/\d{1,}\/ask/.test(path)) {
				this.tabIndex = 2
				this.breadcrumbText = '咨詢問答'
			} else if(/^\/doctor\/\d{1,}\/news/.test(path)) {
				this.tabIndex = 3
				this.breadcrumbText = '健康資訊'
			}
			var doctorid = this.$route.params.doctorId
			if(parseInt(doctorid) == doctorid) {
				this.doctor = JSON.parse(sessionStorage.getItem('detail'))
				this.onRefresh()
			} else {
				this.$router.push('/doctor/list')
			}
		},
		methods: {
			onRefresh: function() {
				this.loadClinicList({
					globaluserid: this.$route.params.doctorId
				}, (_data, _error)=>{
					this.loading = false
					if(_data) {
						var addressList = []
						_data.forEach((d)=>{
							if(!addressList.find(v=>v.v2==d.arealevel2id)) {
								addressList.push({
									v1: d.arealevel1id,
									v2: d.arealevel2id,
									name: d.arealevel2name,
								})
							}
						})
						this.addressList = addressList
						this.doctor = {
							...this.doctor,
							clinicList: _data
						}
					} else {
						if(_error) {
							this._toast.warning(this, _error)
						}
					}
				})
			}
		},
		watch: {
			$route(to,from){
				var path = to.path
				this.breadcrumbText = '醫師簡介'
				this.tabIndex = 1
				if(/^\/doctor\/\d{1,}\/ask$/.test(path)) {
					this.tabIndex = 2
					this.breadcrumbText = '咨詢問答'
				} else if(/^\/doctor\/\d{1,}\/news$/.test(path)) {
					this.tabIndex = 3
					this.breadcrumbText = '健康資訊'
				}

				var doctorid = this.$route.params.doctorId
				if(parseInt(doctorid) == doctorid) {
					this.doctor = JSON.parse(sessionStorage.getItem('detail'))
					this.onRefresh()
				} else {
					this.$router.push('/doctor/list')
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
   #doctor {
	   width: 100%;
	   padding-top: 83px;
	   display: flex;
	   flex-direction: column;
	   background-color: #ebebeb;
   }

   #doctor .content {
	   display: flex;
	   flex-direction: column;
   }

   #doctor .content > .doctor-info {
	   display: flex;
	   flex-direction: column;
	   background-color: #fff;
	   padding-bottom: 40px;
   }

	#doctor .content > .doctor-info > .head {
		display: flex;
		flex-direction: row;
		width: 100%;
		box-sizing: border-box;
		padding: 24px 24px 0px 24px;
	}

	#doctor .content > .doctor-info > .head > .icon-doctor {
		width: 80px;
		height: 80px;
		margin-right: 12px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
		background-image: url(../../../assets/imgs/pc/img_nvys@2x.png);
	}

	#doctor .content > .doctor-info > .head > .icon-doctor.man {
		background-image: url(../../../assets/imgs/pc/img_nanys@2x.png);
	}

	#doctor .content > .doctor-info > .head > .col {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
	
	.doctor-name {
		color: #242020;
		font-size: 34px;
		font-weight: bold;
		margin-top: 6px;
		margin-bottom: 12px;
		line-height: 36px;
	}

	.address-list {
		position: relative;
		box-sizing: border-box;
		padding-left: 24px;
	}

	.address-list::after {
		content: '';
		position: absolute;
		left: 0;
		top: 4px;
        width: 18px;
        height: 24px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/img_cdibiao@2x.png);
	}

	.address-list > .address {
		color: #646464;
		font-size: 24px;
		font-weight: 400;
		line-height: 20px;
		margin-right: 8px;
	}

	.address-list > .address:last-child {
		margin-right: 0px;
	}

	#doctor .content > .doctor-info > .hint {
		font-size: 26px;
		color: #646464;
		font-weight: 400;
		height: 26px;
		line-height: 26px;
		padding-left: 32px;
		position: relative;
		box-sizing: border-box;
		margin: 24px 24px 0px 24px;
	}

	#doctor .content > .doctor-info > .hint::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 24px;
		height: 26px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url(../../../assets/imgs/pc/img_yiyuan@2x.png);
	}

	#doctor .content > .doctor-info > .hint.s::after {
		width: 24px;
		height: 26px;
        background-image: url(../../../assets/imgs/pc/img_biye@2x.png);
	}

	#doctor .content > .doctor-info > .hint.y::after {
		width: 24px;
		height: 26px;
        background-image: url(../../../assets/imgs/pc/img_nianfen@2x.png);
	}

	#doctor .content > .doctor-info > .hint.i::after {
		width: 24px;
		height: 26px;
        background-image: url(../../../assets/imgs/pc/img_jiehsao@2x.png);
	}

	#doctor .content > .doctor-info > .clinic-list {
		display: flex;
		flex-direction: column;
		padding-left: 33px;
		box-sizing: border-box;
		width: 100%;
	}

	#doctor .content > .doctor-info > .clinic-list > .clinic {
		display: flex;
		flex-direction: column;
	}

	#doctor .content > .doctor-info > .clinic-list > .clinic > .clinic-item {
		color: #231F20;
		font-size: 28px;
		line-height: 30px;
		font-weight: 400;
		margin-top: 13px;
	}

	#doctor .content > .doctor-info > .value {
		color: #242020;
		font-size: 28px;
		font-weight: 400;
		line-height: 30px;
		margin-left: 33px;
		margin-top: 13px;
		margin-right: 8px;
	}

   #doctor .content > .col {
	   display: flex;
	   flex-direction: column;
	   flex: 1;
   }

   #doctor .content > .col > .tabs {
	   display: flex;
	   flex-direction: row;
	   height: 90px;
	   width: 100%;
	   box-sizing: border-box;
	   background-color: #fff;
	   justify-content: flex-start;
	   align-items: center;
	   margin-top: 16px;
	   border-bottom: 1px solid #ebebeb;
   }

   #doctor .content > .col > .tabs > .tab {
	   width: 248px;
	   text-align: center;
	   color: #636363;
	   font-size: 28px;
	   font-weight: 400;
	   line-height: 34px;
	   position: relative;
   }

   #doctor .content > .col > .tabs > .tab.current {
	   color:#231F20;
	   font-size: 36px;
	   font-weight: bold;
	   position: relative;
   }

	#doctor .content > .col > .tabs > .tab.current::after {
		content: '';
		position: absolute;
		top: 42px;
		left: 50%;
		transform: translateX(-50%);
		background-color: #36C4D0;
		height: 10px;
		width: 48px;
		border-radius: 5px;
	}

	#doctor .content > .col > .router-view {
	   display: flex;
	   flex-direction: column;
   }
</style>
